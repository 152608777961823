// src/pages/home/home.page.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Home, Info, Healing } from '@mui/icons-material';

// components
// import MeetTheArtist from '../../components/meet-the-artist/meet-the-artist.component';
import HeroCarousel from '../../components/general/heroCarousel/heroCarousel.component';
import ImageWithText from '../../components/general/imageWithText/imageWithText.component';
import MultiColumn from '../../components/general/multiColumn/multiColumn.component';
import RatesAndPolicies from '../../components/home/rates-and-policies/rates-and-policies.component';
import ContactForm from '../../components/general/contact/contact.component';
import Contact from '../../components/home/contact/contact.component';

import './home.page.css';

const HomePage = () => {

  const artist = {
    title: 'Meet the Artist',
    content: ['Austin Anderson is a tattoo artist based in Fort Smith, Arkansas. Specializing in blackwork and neo-traditional styles, he creates bold, clean designs with a focus on floral, animal, anime, and darker themes like witchcraft and the occult. ', 'Austin started his career as an apprentice at Hanging City Body Modifications and now works at Nightshade Tattoos and Oddities, constantly refining his craft.'],
    imageSrc: `/austinHeadshot.jpg`,
    imageAlt: 'Austin Anderson',
    buttonText: 'Read full Bio',
    buttonLink: '/bio',
  };

  const slides = [
    {
      image: '/tattoo1.jpg',
      title: 'Welcome to Austin Anderson Tattoo',
      description: 'Capturing art and expression through custom tattoos.',
    },
    {
      image: '/tattoo2.jpg',
      title: 'Book Your Custom Tattoo Session',
      description: 'Explore bold designs and personalized artistry.',
    },
    {
      image: '/tattoo3.jpg',
      title: 'Art That Tells Your Story',
      description: 'Bringing your ideas to life through ink.',
    },
    {
      image: '/tattoo4.jpg',
      title: 'Custom Tattoos Designed For You',
      description: 'Every tattoo tells a unique story.',
    },
  ];

  const cards = [
    {
      title: 'Rates',
      description: 'Explore our hourly, half-day, and full-day rates for your next tattoo.',
      link: '/rates',
      buttonText: 'View Rates',
    },
    {
      title: 'Policies',
      description: 'Review our policies for appointments, cancellations, and deposits.',
      link: '/policies',
      buttonText: 'Read Policies',
    },
    {
      title: 'Aftercare',
      description: 'Learn how to care for your tattoo and ensure optimal healing.',
      link: '/after-care',
      buttonText: 'Aftercare Info',
    },
  ];

  const contact = {
    fields: [
      { name: 'client_name', label: 'Name', type: 'text', required: true },
      { name: 'client_email', label: 'Email', type: 'email', required: true },
      { name: 'client_phone', label: 'Phone Number', type: 'tel', required: false },
      { name: 'message', label: 'Message', type: 'textarea', required: true },
      { name: 'referenceFile', label: 'Reference File', type: 'file', accept: 'image/*, .pdf' },
    ],
    endpoint: process.env.NODE_ENV === "production" ? `${process.env.REACT_APP_BASE_URL}/api/v1/contact/email` : 'http://localhost:3003/api/v1/contact/email',
    successMessage: "Your request has been submitted successfully!",
    errorMessage: "There was an error processing your request. Please try again.",
    buttonLabel: "Submit"
  }


  const handleSuccess = (data, file) => {
    console.log('Form submitted successfully:', data, file);
  };
  const handleError = (error) => {
    console.error('Form submission error:', error);
  };

  return (
    <div className="home-page">
      <div className="intro-section">
        <HeroCarousel slides={slides} />
      </div>
      <section className="highlights">
        <ImageWithText {...artist} />
        <MultiColumn cards={cards} />
        <ContactForm
          {...contact} />
      </section>
    </div>
  );
};

export default HomePage;
