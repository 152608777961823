// src/App.js
import React, { useState, useEffect, createContext, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { lightTheme, darkTheme } from './theme'; // Import themes

// layouts
import Sidebar from './layouts/sidebar/sidebar.layout.js';
import Footer from './layouts/footer/footer.layout.js';

// pages
import Home from './pages/home/home.page.js';
import BioPage from './pages/bio/bio.page';
import RatesPage from './pages/rates/rates.page';
import AftercarePage from './pages/after-care/after-care.page.js';
import PastWorkPage from './pages/past-work/past-work.page';
import PoliciesPage from './pages/policies/policies.page.js';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// Theme Context
const ThemeModeContext = createContext();

export const useThemeMode = () => useContext(ThemeModeContext);

const App = () => {
  const [themeMode, setThemeMode] = useState('light');

  // Update the body's data-theme attribute
  useEffect(() => {
    document.body.setAttribute('data-theme', themeMode);
  }, [themeMode]);

  // Function to toggle theme
  const toggleTheme = () => {
    setThemeMode((prev) => (prev === 'light' ? 'dark' : 'light'));
  };

  return (
    <ThemeModeContext.Provider value={{ themeMode, toggleTheme }}>
      <ThemeProvider theme={themeMode === 'light' ? lightTheme : darkTheme}>
        <CssBaseline />
        <Router>
          <div className="app-container">
            {/* Sidebar Component */}
            <Sidebar toggleTheme={toggleTheme} themeMode={themeMode} />

            {/* Main Content Area */}
            <main className="page-content">
              <Routes>
                <Route path="/bio" element={<BioPage />} />
                <Route path="/rates" element={<RatesPage />} />
                <Route path="/after-care" element={<AftercarePage />} />
                <Route path="/past-work" element={<PastWorkPage />} />
                <Route path="/policies" element={<PoliciesPage />} />
                <Route path="/" element={<Home />} />
              </Routes>
            </main>

            {/* Footer Component */}
            <Footer />
          </div>
        </Router>
      </ThemeProvider>
    </ThemeModeContext.Provider>
  );
};

export default App;
