import React from 'react';
import { Container, Box, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import { Brush, Work, Star } from '@mui/icons-material';

// Component imports
import Hero from '../../components/general/hero/hero.component';
import ImageWithText from '../../components/general/imageWithText/imageWithText.component';
import BioSection from '../../components/bio/bioSection/bioSection.component';
import HighlightsSection from '../../components/bio/highlightsSection/highlightsSection.component';
import TestimonialsCarousel from '../../components/bio/testimonialsCarousel/testimonialsCarousel.component';
import CallToAction from '../../components/general/callToAction/callToAction.component';

const BioPage = () => {
  const theme = useTheme();

  const heroData = {
    backgroundImage: '/bio.hero.jpg',
    title: 'Meet Austin Anderson',
  };

  const bioContent = [
    "I’m Austin Anderson, a tattoo artist based in Fort Smith, Arkansas. I specialize in blackwork and neo-traditional styles, focusing on floral designs, animals, anime, and darker themes like witchcraft and the occult. My work is clean, bold, and built to last.",
    "Before tattooing, I worked as a muralist, graphic designer, and painter, creating everything from large-scale art to custom merch. That background gave me a solid foundation in design and composition, which I bring into every tattoo I create.",
  ];

  const highlights = [
    {
      title: 'Specialty',
      description: 'Blackwork and Neo-Traditional styles with bold, clean designs.',
      icon: <Brush />,
    },
    {
      title: 'Experience',
      description: 'Former muralist and graphic designer with a foundation in large-scale art.',
      icon: <Work />,
    },
    {
      title: 'Philosophy',
      description: 'Creating tattoos that clients are proud to wear—sharp, detailed, and personal.',
      icon: <Star />,
    },
  ];

  const testimonials = [
    {
      id: 1,
      text: "I got my very first tattoo from Austin when he was still an apprentice and it is my absolute favorite thing EVER! Means so much to me and he did it absolutely perfect! 10/10 recommend to everyone who mentions wanting a tattoo!",
      name: "Sabrina Odom",
      rating: 5,
    },
    {
      id: 2,
      text: "Great experience. I will forever cherish the three tattoos Austin did for me. He has a really welcoming, great personality and a good spirit! I hope to get more in the future from their company!!",
      name: "Abby Parker",
      rating: 5,
    },
    {
      id: 3,
      text: "Absolutely love this place! So far I've only gotten tattoos from Austin but I absolutely love them and intend on getting many more from him!",
      name: "Dominic Harrison",
      rating: 5,
    },
  ];

  const ctaData = {
    heading: "Check Out My Work",
    description:
      "Explore a collection of custom tattoos designed to bring stories to life. From bold blackwork to intricate details, find inspiration for your next piece.",
    buttonText: "View Past Work",
    buttonLink: "/past-work"
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: 'easeOut' }}
    >
      {/* Hero Section */}
      <Hero backgroundImage={heroData.backgroundImage} title={heroData.title} />

      {/* Bio Section */}
      <Container
        maxWidth="md"
        sx={{
          py: 6,
          backgroundColor: theme.palette.background.paper,
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <BioSection
          imageSrc="/austinHeadshot.jpg"
          imageAlt="Austin Anderson"
          bioContent={bioContent}
        />
      </Container>

      {/* Highlights Section */}
      <HighlightsSection highlights={highlights} />

      {/* Testimonials Carousel */}
      <TestimonialsCarousel testimonials={testimonials} />

      {/* Call-to-Action */}
      <CallToAction {...ctaData} />
    </motion.div>
  );
};

export default BioPage;
