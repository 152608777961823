import React, { useState } from 'react';
import { Box, Container, Typography, Pagination, useTheme } from '@mui/material';
import Gallery from '../../components/pastWork/gallery/gallery.component';
import ImageModal from '../../components/pastWork/imageModal/imageModal.component';

const GalleryPage = () => {
  const theme = useTheme();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedImage, setSelectedImage] = useState(null);
  const [zoomStyles, setZoomStyles] = useState({ transform: 'scale(1)', transformOrigin: 'center' });

  const images = [
    // Add your image objects here
  ];

  const imagesPerPage = 6;
  const totalPages = Math.ceil(images.length / imagesPerPage);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setZoomStyles({ transform: 'scale(1)', transformOrigin: 'center' });
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  const handleMouseMove = (event) => {
    if (!selectedImage) return;
    const { clientX, clientY, target } = event;
    const { left, top, width, height } = target.getBoundingClientRect();

    const x = ((clientX - left) / width) * 100;
    const y = ((clientY - top) / height) * 100;

    setZoomStyles({
      transform: 'scale(2)',
      transformOrigin: `${x}% ${y}%`,
    });
  };

  const handleTouchMove = (event) => {
    if (!selectedImage || !event.touches.length) return;
    const { clientX, clientY, target } = event.touches[0];
    const { left, top, width, height } = target.getBoundingClientRect();

    const x = ((clientX - left) / width) * 100;
    const y = ((clientY - top) / height) * 100;

    setZoomStyles({
      transform: 'scale(2)',
      transformOrigin: `${x}% ${y}%`,
    });
  };

  const startIndex = (currentPage - 1) * imagesPerPage;
  const currentImages = images.slice(startIndex, startIndex + imagesPerPage);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        py: 6,
        minHeight: '100vh',
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h3"
          align="center"
          gutterBottom
          sx={{ mb: 4, color: theme.palette.text.primary }}
        >
          Gallery
        </Typography>

        {/* Gallery Component */}
        <Gallery
          images={currentImages}
          onImageClick={handleImageClick}
        />

        {/* Pagination */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 4,
          }}
        >
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            size="large"
          />
        </Box>

        {/* Modal Component */}
        <ImageModal
          open={!!selectedImage}
          onClose={handleCloseModal}
          image={selectedImage}
          zoomStyles={zoomStyles}
          handleMouseMove={handleMouseMove}
          handleTouchMove={handleTouchMove}
        />
      </Container>
    </Box>
  );
};

export default GalleryPage;
