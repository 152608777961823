import { createTheme } from '@mui/material/styles';

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#F4ECE4', // var(--background-color)
      paper: '#FFF8F2', // var(--section-background)
    },
    text: {
      primary: '#333333', // var(--text-color)
    },
    primary: {
      main: '#8A3324', // var(--accent-color)
    },
    secondary: {
      main: '#B2543B', // var(--accent-color2)
    },
  },
  typography: {
    fontFamily: '"Oswald", sans-serif',
    fontWeightLight: 200, // var(--oswald-font-weight-light)
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          transition: 'background-color 0.3s ease',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent !important' , // No background
          borderRadius: 0, // No border radius
          boxShadow: 'none !important', // No shadow
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 8, // Matches your section styling
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // var(--shadow-color)
        },
      },
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#2B2B2B', // var(--background-color for dark)
      paper: '#33302E', // var(--section-background for dark)
    },
    text: {
      primary: '#EAEAEA', // var(--text-color for dark)
    },
    primary: {
      main: '#A3472B', // var(--accent-color for dark)
    },
    secondary: {
      main: '#BF6650', // var(--accent-color2 for dark)
    },
  },
  typography: {
    fontFamily: '"Oswald", sans-serif',
    fontWeightLight: 200,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          transition: 'background-color 0.3s ease',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent !important' , // No background
          borderRadius: 0, // No border radius
          boxShadow: 'none !important', // No shadow
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.6)', // Adjusted for dark theme
        },
      },
    },
  },
});
