import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import './bioSection.component.css';

const BioSection = ({ imageSrc, imageAlt, bioContent = [] }) => {
  return (
    <Container
      maxWidth="lg"
      sx={{
        py: { xs: 4, sm: 6, md: 8 },
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'center',
        gap: 4,
      }}
      className="bio-section-container"
    >
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        style={{ flex: 1 }}
      >
        {/* Image Section */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            mb: { xs: 4, md: 0 },
          }}
        >
          <img
            src={imageSrc}
            alt={imageAlt}
            className="bio-image"
            style={{
              width: '100%',
              maxWidth: '400px',
              borderRadius: '12px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
            }}
          />
        </Box>
      </motion.div>

      {/* Text Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.2 }}
        style={{ flex: 1 }}
      >
        <Box>
          <Typography
            variant="h4"
            component="h2"
            sx={{
              fontWeight: 'bold',
              mb: 3,
              textAlign: { xs: 'center', md: 'left' },
            }}
          >
            Meet Austin Anderson
          </Typography>
          {bioContent.map((paragraph, index) => (
            <Typography
              key={index}
              variant="body1"
              sx={{
                mb: 2,
                lineHeight: 1.8,
                textAlign: { xs: 'center', md: 'left' },
              }}
            >
              {paragraph}
            </Typography>
          ))}
        </Box>
      </motion.div>
    </Container>
  );
};

export default BioSection;
