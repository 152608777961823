// src/components/bio/testimonials-carousel/testimonials-carousel.component.js
import React from 'react';
import { Container, Box, Typography, useTheme, Rating } from '@mui/material';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TestimonialsCarousel = ({ testimonials }) => {
  const theme = useTheme();

  const settings = {
    dots: true, // Display navigation dots
    arrows: false, // Remove navigation arrows
    infinite: true, // Infinite loop
    speed: 500, // Animation speed
    slidesToShow: 1, // Show one slide at a time
    slidesToScroll: 1, // Scroll one slide at a time
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Autoplay interval (3 seconds)
  };

  return (
    <Box
      sx={{
        py: 6,
        backgroundColor: theme.palette.background.default,
        borderRadius: 2,
        position: 'relative',
        height: '500px',
        background: `
          linear-gradient(
            rgba(0, 0, 0, 0.5), 
            rgba(0, 0, 0, 0.5)
          ),
          url('/testimonial.bg.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Container maxWidth="sm">
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{
            color: 'white',
          }}
        >
          Google Reviews
        </Typography>
        <Slider {...settings}>
          {testimonials.map((review) => (
            <Box
              key={review.id}
              sx={{
                p: 4,
                textAlign: "center",
                borderRadius: 2,
                color: 'white',
              }}
            >
              <Typography variant="body1" sx={{ fontStyle: "italic", mb: 2 }}>
                "{review.text}"
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 'bold', mb: 1 }}
              >
                - {review.name}
              </Typography><Rating
                value={review.rating}
                readOnly
                precision={0.5}
                sx={{
                  color: '#FFD700', // Hex code for gold
                }}
              />

            </Box>
          ))}
        </Slider>
      </Container>
    </Box>
  );
};

export default TestimonialsCarousel;
