import React from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Link as MuiLink,
  useTheme,
} from '@mui/material';
import ContactForm from '../../components/general/contact/contact.component';
import ServiceCard from '../../components/general/serviceCards/serviceCards.component';
import CallToAction from '../../components/general/callToAction/callToAction.component';

export const services = [
  { id: 'full_day', name: 'Full Day', duration: '8 hours', price: 1100, details: 'Perfect for larger, more detailed pieces or multiple tattoos in a single session.' },
  { id: 'half_day', name: 'Half Day Session', duration: '4 hours', price: 600, details: 'Ideal for medium-sized pieces or detailed smaller tattoos.' },
  { id: 'hourly_rate', name: 'Hourly Rate', duration: '1 hour', price: 150, details: 'Hourly rate for smaller tattoos or touch-ups.' },
  { id: 'minimum', name: 'Minimum', duration: 'Less than 1 hour', price: 80, details: 'Minimum charge for any tattoo.' },
];

const contact = {
  fields: [
    { name: 'client_name', label: 'Name', type: 'text', required: true },
    { name: 'client_email', label: 'Email', type: 'email', required: true },
    { name: 'client_phone', label: 'Phone Number', type: 'tel', required: false },
    { name: 'message', label: 'Message', type: 'textarea', required: true },
    { name: 'referenceFile', label: 'Reference File', type: 'file', accept: 'image/*, .pdf' },
  ],
  endpoint:
    process.env.NODE_ENV === 'production'
      ? `${process.env.REACT_APP_BASE_URL}/api/v1/contact/email`
      : 'http://localhost:3003/api/v1/contact/email',
  successMessage: 'Your request has been submitted successfully!',
  errorMessage: 'There was an error processing your request. Please try again.',
  buttonLabel: 'Submit',
};

const content = [
  'A $50-$100 non-refundable deposit is required before booking any appointment. This deposit will be deducted from the final cost of your tattoo.',
];

const RatesPage = () => {
  const theme = useTheme();

  const handleSuccess = (data, file) => {
    console.log('Form submitted successfully:', data, file);
  };

  const handleError = (error) => {
    console.error('Form submission error:', error);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Call to Action */}
      <CallToAction heading="Rates & Deposit Information" content={content} />

      {/* Deposit Info */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
          Appointment Rates
        </Typography>
        <Typography variant="body1" sx={{ color: theme.palette.text.secondary, mb: 4 }}>
          A $50-$100 <strong>non-refundable deposit</strong> is required before booking any appointment. This deposit will be deducted from the final cost of your tattoo.
        </Typography>
      </Box>

      {/* Service Cards */}
      <Grid container spacing={4}>
        {services.map((service) => (
          <Grid item xs={12} sm={6} md={4} key={service.id}>
            <ServiceCard
              name={service.name}
              price={service.price}
              duration={service.duration}
              details={service.details}
            />
          </Grid>
        ))}
      </Grid>

      {/* Additional Info */}
      <Box sx={{ mt: 6, textAlign: 'center' }}>
        <Typography variant="body1">
          All bookings require a non-refundable deposit. Send your design to{' '}
          <MuiLink href="mailto:austinandersontattoos@gmail.com" underline="hover">
            austinandersontattoos@gmail.com
          </MuiLink>{' '}
          within 24 hours of booking, or use the form below. You will be notified upon appointment
          acceptance. <strong>No deposit, no appointment.</strong>
        </Typography>
      </Box>

      {/* Contact Form */}
      <Box sx={{ mt: 6 }}>
        <ContactForm
          fields={contact.fields}
          endpoint={contact.endpoint}
          successMessage={contact.successMessage}
          errorMessage={contact.errorMessage}
          buttonLabel={contact.buttonLabel}
          onSubmitSuccess={handleSuccess}
          onSubmitError={handleError}
        />
      </Box>
    </Container>
  );
};

export default RatesPage;
