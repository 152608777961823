import React from 'react';
import Grid from '@mui/material/Grid2';
import { Box, Typography, Container, useTheme } from '@mui/material';

const HighlightsSection = ({ highlights }) => {
  const theme = useTheme();

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Grid 
        container 
        spacing={6} 
        justifyContent="center" // Center the entire grid
        alignItems="stretch"   // Ensure cards align evenly
      >
        {highlights.map((highlight, index) => (
          <Grid
            key={index}
            item
            xs={12}
            sm={6}
            md={4}
            display="flex"
            justifyContent="center" // Center each grid item horizontally
          >
            <Box
              sx={{
                width: '100%', // Ensure box fills its grid
                maxWidth: 300, // Constrain max size
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                padding: 4,
                backgroundColor: theme.palette.background.paper,
                borderRadius: 2,
                boxShadow: 4,
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: 10,
                },
              }}
            >
              {/* Icon Section */}
              <Box
                sx={{
                  width: 60,
                  height: 60,
                  borderRadius: '50%',
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: 30,
                  mb: 2,
                }}
              >
                {highlight.icon}
              </Box>

              {/* Title */}
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                {highlight.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.text.secondary,
                  fontSize: '1rem',
                  lineHeight: 1.6,
                }}
              >
                {highlight.description}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default HighlightsSection;
