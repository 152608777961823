import React from 'react';
import {
  Box,
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HealingIcon from '@mui/icons-material/Healing';
import DropIcon from '@mui/icons-material/Opacity';
import SoapIcon from '@mui/icons-material/Soap';

const AftercarePage = () => {
  const theme = useTheme();

  const aftercareSections = [
    {
      title: 'Second Skin Aftercare',
      icon: <HealingIcon color="primary" />,
      content: (
        <>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Leave the second skin bandage on for 4-5 days. Some buildup of liquid, ink, and blood under the bandage is normal and beneficial for healing.
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Slight peeling at the edges of the bandage is fine as long as the tattoo remains covered. <strong>Important:</strong> Do not pick at the bandage.
          </Typography>
          <Typography variant="body1">
            If fluid leaks from the edges, follow the "Leaking Bandage" instructions below.
          </Typography>
        </>
      ),
    },
    {
      title: 'Bandage Removal (Day 4 or 5)',
      icon: <DropIcon color="primary" />,
      content: (
        <List>
          {[
            'Wash your hands thoroughly with antibacterial soap.',
            'In the shower, wash the outside of the bandage with warm water.',
            'Gently peel the bandage off in the direction of hair growth.',
            'Wash the tattoo with unscented antibacterial soap (e.g., Dial Gold).',
            'Pat the tattoo dry with a clean, separate towel.',
          ].map((step, index) => (
            <ListItem key={index} sx={{ display: 'list-item', pl: 2 }}>
              <ListItemText primary={step} />
            </ListItem>
          ))}
        </List>
      ),
    },
    {
      title: 'Moisturizing',
      icon: <SoapIcon color="primary" />,
      content: (
        <Typography variant="body1">
          Apply a small amount of fragrance-free lotion (e.g., Lubriderm) to the tattoo. Start with a pea-sized amount, adding more only if needed. Avoid over-moisturizing—lotion won't speed up healing.
        </Typography>
      ),
    },
    {
      title: 'Ongoing Care (3 Weeks)',
      icon: <HealingIcon color="primary" />,
      content: (
        <List>
          {[
            'Wash the tattoo 2-3 times daily with unscented antibacterial soap.',
            'Apply a thin layer of lotion after washing or whenever the tattoo feels dry or ashy.',
            'Always wash your hands before touching your tattoo.',
          ].map((tip, index) => (
            <ListItem key={index} sx={{ display: 'list-item', pl: 2 }}>
              <ListItemText primary={tip} />
            </ListItem>
          ))}
        </List>
      ),
    },
    {
      title: 'If Bandage Leaks',
      icon: <DropIcon color="primary" />,
      content: (
        <>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Remove the bandage in the shower as described above.
          </Typography>
          <Typography variant="body1">
            Pay extra attention to keeping the tattoo clean. Wait until day 3 or 4 before applying lotion.
          </Typography>
        </>
      ),
    },
    {
      title: 'Additional Tips',
      icon: <HealingIcon color="primary" />,
      content: (
        <List>
          {[
            'Always consult your artist with questions about healing.',
            'Avoid searching online for aftercare advice—trust your artist.',
          ].map((tip, index) => (
            <ListItem key={index} sx={{ display: 'list-item', pl: 2 }}>
              <ListItemText primary={tip} />
            </ListItem>
          ))}
        </List>
      ),
    },
  ];

  return (
    <Box sx={{ backgroundColor: theme.palette.background.default, py: 6 }}>
      <Container maxWidth="md">
        {/* Page Header */}
        <Box
          sx={{
            mb: 4,
            textAlign: 'center',
            color: theme.palette.text.primary,
          }}
        >
          <Typography variant="h2" sx={{ fontWeight: 'bold', mb: 1 }}>
            Tattoo Aftercare Instructions
          </Typography>
          <Typography variant="subtitle1" sx={{ color: theme.palette.text.secondary }}>
            Follow these instructions carefully to ensure your tattoo heals properly.
          </Typography>
        </Box>

        {/* Sections */}
        {aftercareSections.map((section, index) => (
          <Accordion
            key={index}
            sx={{
              mb: 3,
              '&:before': { display: 'none' },
              boxShadow: 3,
              borderRadius: 2,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                backgroundColor: theme.palette.background.paper,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {section.icon}
              <Typography variant="h6" sx={{ ml: 2 }}>
                {section.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>{section.content}</AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Box>
  );
};

export default AftercarePage;
